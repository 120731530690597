<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12">
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="12">
            <v-card>
              <div style="padding:5px;">
                <v-row>
                  <v-col cols="12" sm="12" md="3" lg="6">
                    <v-btn
                      class="mx-3"
                      fab
                      dark
                      color="indigo"
                      v-on:click="OpenDialogRate()"
                    >
                      <v-icon dark>fa fa-align-justify</v-icon>
                    </v-btn>
                    <v-btn
                      class="mx-3"
                      fab
                      dark
                      color="indigo"
                      v-on:click="Next()"
                    >
                      <v-icon dark>fa fa-history</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" sm="12" md="3" lg="2">
                    <v-date
                      v-model="BillSalesDate"
                      :disabled="LevelStatus"
                    ></v-date>
                  </v-col>
                  <v-col cols="12" sm="12" md="3" lg="2">
                    <v-btn
                      class="mx-3"
                      fab
                      dark
                      color="indigo"
                      v-on:click="insert()"
                    >
                      <v-icon dark>fa fa-plus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="12">
            <v-card>
              <v-simple-table height="40vh" dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">แปลง</th>
                      <th class="text-left">หมายเหตุ</th>
                      <th class="text-left">น้ำหนัก - น้ำหนักตระกล้า</th>
                      <th class="text-center">น้ำหนักรวม</th>
                      <th class="text-center">%</th>
                      <th class="text-center">ยางแห้ง</th>
                      <th class="text-right">ราคา</th>
                      <th class="text-right">รวมเงิน</th>
                      <th class="text-right"></th>
                      <th class="text-right"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in dsmainOpen"
                      :key="item.BillSaleId"
                      @click="Showhis(item)"
                    >
                      <td width="300">
                        <v-autocomplete
                          @change="OnSave(item)"
                          dense
                          v-model="item.RubberId"
                          placeholder="เลือกแปลง"
                          :items="rubber"
                          required
                        >
                        </v-autocomplete>
                      </td>
                      <td width="200">
                        <v-textarea
                          @change="OnSave(item)"
                          dense
                          v-model="item.BillSaleRemark"
                          class="mx-2"
                          rows="1"
                        ></v-textarea>
                      </td>
                      <td width="300">
                        <v-text-field
                          @change="OnSave(item)"
                          class="ww"
                          dense
                          required
                          v-model="item.RubberWeight"
                          type="text"
                        ></v-text-field
                        >-
                        <v-text-field
                          @change="OnSave(item)"
                          class="ww"
                          dense
                          required
                          v-model="item.RubberWeightBox"
                          type="text"
                        ></v-text-field>
                      </td>
                      <td class="text-center" width="100">
                        <v-text-field
                          readonly
                          dense
                          required
                          v-model="item.RubberWeightTotal"
                          type="text"
                        ></v-text-field>
                      </td>
                      <td class="text-center" width="100">
                        <v-text-field
                          @change="OnSavePercent(item)"
                          dense
                          required
                          v-model="item.RubberPercent"
                          type="text"
                        ></v-text-field>
                      </td>
                      <td class="text-center" width="100">
                        <v-text-field
                          readonly
                          dense
                          required
                          v-model="item.RubberWeightDry"
                          type="text"
                        ></v-text-field>
                      </td>
                      <td class="text-center" width="100">
                        <v-text-field
                          dense
                          required
                          @change="OnSave(item)"
                          v-model="item.BillSalesPriceToday"
                          type="text"
                        ></v-text-field>
                      </td>
                      <td class="text-center" width="130">
                        <v-text-field
                          dense
                          readonly
                          required
                          v-model="item.BillSalesTotal"
                          type="text"
                        ></v-text-field>
                      </td>
                      <td class="text-right">
                        <v-btn
                          fab
                          dark
                          small
                          color="green"
                          @click="OnPayMent(item)"
                        >
                          <v-icon dark>fa fa-coins</v-icon>
                        </v-btn>
                      </td>
                      <td class="text-right">
                        <v-btn
                          fab
                          dark
                          small
                          color="red"
                          @click="OnDelete(item)"
                        >
                          <v-icon dark>fa fa-trash-alt</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="12">
            <v-card>
              <v-simple-table height="30vh" dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">วันที่</th>
                      <th class="text-left">แปลง</th>
                      <th class="text-left">หมายเหตุ</th>
                      <th class="text-center">น้ำหนัก - น้ำหนักตระกล้า</th>
                      <th class="text-center">น้ำหนักรวม</th>
                      <th class="text-center">%</th>
                      <th class="text-center">ยางแห้ง</th>
                      <th class="text-right">ราคา</th>
                      <th class="text-right">รวมเงิน</th>
                      <th class="text-right">จ่าย</th>
                      <th class="text-right">ค้าง</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in dsmainhis" :key="item.BillSaleId">
                      <td width="200">
                        <v-text-field
                          readonly
                          dense
                          required
                          v-model="item.BillSalesDate"
                          type="text"
                        ></v-text-field>
                      </td>
                      <td width="200">
                        <v-autocomplete
                          readonly
                          dense
                          v-model="item.RubberId"
                          placeholder="เลือกแปลง"
                          :items="rubber"
                          required
                        ></v-autocomplete>
                      </td>
                      <td class="text-center" width="200">
                        <v-text-field
                          readonly
                          dense
                          required
                          v-model="item.BillSaleRemark"
                          type="text"
                        ></v-text-field>
                      </td>
                      <td width="200">
                        <v-text-field
                          readonly
                          class="ww"
                          dense
                          required
                          v-model="item.RubberWeight"
                          type="text"
                        ></v-text-field
                        >-
                        <v-text-field
                          readonly
                          class="ww"
                          dense
                          required
                          v-model="item.RubberWeightBox"
                          type="text"
                        ></v-text-field>
                      </td>
                      <td class="text-center" width="100">
                        <v-text-field
                          readonly
                          dense
                          required
                          v-model="item.RubberWeightTotal"
                          type="text"
                        ></v-text-field>
                      </td>
                      <td class="text-center" width="100">
                        <v-text-field
                          readonly
                          dense
                          required
                          v-model="item.RubberPercent"
                          type="text"
                        ></v-text-field>
                      </td>
                      <td class="text-center" width="100">
                        <v-text-field
                          readonly
                          dense
                          required
                          v-model="item.RubberWeightDry"
                          type="text"
                        ></v-text-field>
                      </td>
                      <td class="text-center" width="100">
                        <v-text-field
                          dense
                          required
                          readonly
                          v-model="item.BillSalesPriceToday"
                          type="text"
                        ></v-text-field>
                      </td>
                      <td class="text-center" width="130">
                        <v-text-field
                          dense
                          readonly
                          required
                          v-model="item.BillSalesTotal"
                          type="text"
                        ></v-text-field>
                      </td>
                      <td class="text-center" width="130">
                        <v-text-field
                          dense
                          readonly
                          required
                          v-model="item.BillSaleReceive"
                          type="text"
                        ></v-text-field>
                      </td>
                      <td class="text-center" width="130">
                        <v-text-field
                          dense
                          readonly
                          required
                          v-model="item.BillSaleStale"
                          type="text"
                        ></v-text-field>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="900">
        <v-card>
          <v-card-title class="headline"></v-card-title>
          <v-card-text>
            <v-row v-for="item in dialogContent" :key="item.BillSaleDetailId">
              <v-col cols="12" sm="12" md="4" lg="4">
                <v-text-field
                  v-model="item.Member.UserName"
                  :label="
                    item.Member.UserLevel == 3 ? 'เจ้าของสวน' : 'คนกรีดยาง'
                  "
                  :placeholder="
                    item.Member.UserLevel == 3 ? 'เจ้าของสวน' : 'คนกรีดยาง'
                  "
                  dense
                  required
                  readonly
                  type="text"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="2" lg="2">
                <v-text-field
                  v-model="item.Total"
                  dense
                  required
                  readonly
                  label="จำนวนเงิน"
                  placeholder="0"
                  type="text"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="2" lg="2">
                <v-text-field
                  v-model="item.Percent"
                  label="่ส่วนแบ่ง %"
                  placeholder="%"
                  readonly
                  dense
                  required
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="4" lg="2">
                <v-text-field
                  v-model="item.Receive"
                  label="จ่าย"
                  @change="SaveOnPayMent(item)"
                  placeholder="0"
                  dense
                  required
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="4" lg="2">
                <v-text-field
                  :append-outer-icon="
                    item.BillSales.BillSaleState != 1 ? 'fa fa-print' : ''
                  "
                  @click:append-outer="PrintBill(item.BillSaleDetailId, 1, 0)"
                  v-model="item.Stale"
                  @change="SaveOnPayMentStale(item)"
                  label="ค้าง"
                  placeholder="0"
                  dense
                  required
                  type="number"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn x-large color="success" @click="dialog = false" dark>
              <span v-if="dialogState == 1">ยกเลิก</span>
              <span v-if="dialogState == 0">เสร็จสิ้น</span>
            </v-btn>

            <v-btn
              v-if="dialogState == 1"
              x-large
              color="success"
              dark
              @click="SavePayMent"
              >บันทึก</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="dialogListLog" persistent max-width="900">
        <v-card>
          <v-card-title class="headline"></v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12" md="6" lg="4">
                <v-date
                  v-model="RatesData.BillSaleRateDateDate"
                  :disabled="LevelStatus"
                ></v-date>
              </v-col>
              <v-col cols="12" sm="12" md="3" lg="2">
                <v-text-field
                  v-model="RatesData.BillSaleRateDatePercent"
                  label="่เรท"
                  placeholder="%"
                  required
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="3" lg="2">
                <v-text-field
                  v-model="RatesData.BillSaleRateDateTotal"
                  label="ราคา"
                  placeholder="0.00"
                  required
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="4">
                <v-btn
                  x-large
                  color="indigo"
                  class="mr-1"
                  dark
                  @click="SaveRate()"
                  >เพิ่ม</v-btn
                >
                <v-btn x-large color="red" dark @click="dialogListLog = false"
                  >ปิด</v-btn
                >
              </v-col>
            </v-row>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">เรท</th>
                    <th class="text-left">ราคา</th>
                    <th class="text-left"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in RatesList" :key="item.BillSaleRateDateId">
                    <td>{{ item.BillSaleRateDatePercent }}</td>
                    <td>{{ item.BillSaleRateDateTotal }}</td>
                    <td width="30">
                      <v-btn
                        color="red"
                        fab
                        dense
                        x-small
                        dark
                        @click="DeleteRate(item.BillSaleRateDateId)"
                      >
                        <v-icon dark>fa fa-times-circle</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>
<script>
import { mapState } from "vuex";
import Axios from "axios";
export default {
  data: () => ({
    dialogContent: [],
    dialog: false,
    dialogListLog: false,
    dsmainOpen: [],
    dsmainClose: [],
    dsmainWait: [],
    rubber: [],
    dsmainhis: [],
    RatesList: [],
    RatesData: {
      BillSaleRateDateDate: "",
      BillSaleRateDatePercent: "",
      BillSaleRateDateTotal: "",
    },
    BillSalesDate: "",
    ShowHist: {},
    dialogState: 0,
    dialogdata: {},
  }),
  mounted() {
    this.$nextTick(() => {
      this.selectRubber();
    });
  },
  watch: {
    BillSalesDate() {
      this.selectOpen();
    },
    "RatesData.BillSaleRateDateDate"() {
      this.SelectRate();
    },
  },
  computed: {
    ...mapState(["User"]),
    LevelStatus() {
      if (this.User.UserLevel == "M") {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    SaveOnPayMentStale(v) {
      v.Receive = Number(v.Total) - Number(v.Stale);
      v.Receive = v.Receive < 0 ? 0 : v.Receive;
      this.SaveOnPayMent(v);
    },
    Next() {
      this.$router.push(`Billsale/History?Date=${this.BillSalesDate}`);
    },
    Showhis(v) {
      if (v.BillSaleId != this.ShowHist.BillSaleId) {
        if (v.RubberId != null) {
          this.OnChoose(v);
        }
      }
      this.ShowHist = v;
    },
    onLimit(a, b) {
      if (this.dialogContent[a] > this.dialogContent[b]) {
        this.dialogContent[a] = this.dialogContent[b];
      }
    },
    OpenDialogRate() {
      this.dialogListLog = true;
      this.SelectRate();
    },
    SelectRate() {
      Axios.get(
        "/api/V1/Select/Select/BillSaleRateDate?Date=" +
          this.RatesData.BillSaleRateDateDate
      ).then((res) => {
        if (res.status == 200) {
          this.RatesList = res.data;
          // this.noti.Show("บันทึกเสร็จสิ้น");
        }
      });
    },
    SaveRate() {
      var data = {
        BillSaleRateDateDate: this.RatesData.BillSaleRateDateDate,
        BillSaleRateDatePercent:
          this.RatesData.BillSaleRateDatePercent == null ||
          this.RatesData.BillSaleRateDatePercent == ""
            ? 0
            : this.RatesData.BillSaleRateDatePercent,
        BillSaleRateDateTotal:
          this.RatesData.BillSaleRateDateTotal == null ||
          this.RatesData.BillSaleRateDateTotal == ""
            ? 0
            : this.RatesData.BillSaleRateDateTotal,
      };
      Axios.post("/Api/V1/Insert/Insert/BillSaleRateDate", data).then((res) => {
        if (res.status == 200) {
          this.noti.Show("บันทึกเสร็จสิ้น");
          this.SelectRate();
        }
      });
    },
    DeleteRate(v) {
      Axios.delete(
        "/api/v1/Delete/BillSaleRateDate?BillSaleRateDateId=" + v
      ).then((res) => {
        if (res.status == 200) {
          this.noti.Show("ลบแล้ว");
          this.SelectRate();
        }
      });
    },
    SavePayMent() {
      Axios.post("/api/v1/SavePayMent/billsale", {
        BillSaleId: this.dialogdata.BillSaleId,
      }).then((res) => {
        if (res.status == 200) {
          this.OnPayMent({
            BillSaleId: this.dialogdata.BillSaleId,
            RubberId: this.dialogdata.RubberId,
          });
          this.selectOpen();
        }
      });
    },
    SaveOnPayMent(v) {
      let data = {
        BillSaleDetailId: v.BillSaleDetailId,
        Percent: v.Percent == "" ? 0 : v.Percent,
        Receive: v.Receive == "" ? 0 : v.Receive,
      };
      Axios.post("/api/v1/SaveOnPayMent/billsale", data).then((res) => {
        if (res.status == 200) {
          this.noti.Show("บันทึกเสร็จสิ้น");
          this.OnPayMent({
            BillSaleId: this.dialogdata.BillSaleId,
            RubberId: this.dialogdata.RubberId,
          });
        }
      });
    },
    PrintBill(BillSaleId, MemberType, type) {
      // type  = 0 ค้างจ่าย
      // type  = 1 จ่าย
      // Worker  = 0
      // Owner  = 1
      Axios.get(
        "/api/v1/billsale/pdf?BillSaleDetailId=" +
          BillSaleId +
          "&MemberType=" +
          MemberType +
          "&type=" +
          type
      ).then((res) => {
        if (res.status == 200) {
          this.PrintJs({
            printable: res.data.base64,
            type: "pdf",
            base64: true,
          });
        }
      });
    },
    insert() {
      Axios.post("/api/v1/insert/insert/billsale", {
        BillSalesDate: this.BillSalesDate,
      }).then((res) => {
        if (res.status == 200) {
          this.selectOpen();
          this.selectRubber();
        }
      });
    },
    selectOpen() {
      Axios.get(
        "/api/v1/Select/SelectOpen/billsale?DateTime=" + this.BillSalesDate
      ).then((res) => {
        if (res.status == 200) {
          this.dsmainOpen = res.data;
        }
      });
    },
    OnChoose(item) {
      Axios.get("/api/v1/SelectRubber/billsale?RubberId=" + item.RubberId).then(
        (res) => {
          if (res.status == 200) {
            this.dsmainhis = res.data;
          }
          this.selectRubber();
        }
      );
    },
    selectRubber() {
      Axios.get(
        "/api/v1/select/select/rubber?BillSaleDate=" + this.BillSalesDate
      ).then((res) => {
        if (res.status == 200) {
          this.rubber = res.data;
        }
      });
    },
    OnSave(v) {
      var Data = {
        BillSaleId: v.BillSaleId,
        RubberId: v.RubberId,
        BillSaleRemark: v.BillSaleRemark,
        RubberWeight: v.RubberWeight == "" ? 0 : v.RubberWeight,
        RubberWeightBox: v.RubberWeightBox == "" ? 0 : v.RubberWeightBox,
        RubberPercent: v.RubberPercent == "" ? 0 : v.RubberPercent,
        BillSalesPriceToday:
          v.BillSalesPriceToday == "" ? 0 : v.BillSalesPriceToday,
      };
      Axios.put("/api/v1/OnSave/billsale", Data).then((res) => {
        if (res.status == 200) {
          for (let index = 0; index < this.dsmainOpen.length; index++) {
            if (this.dsmainOpen[index].BillSaleId == v.BillSaleId) {
              this.dsmainOpen[index] = res.data;
            }
          }
          this.OnChoose(v);
          //this.selectRubber();
        }
      });
    },
    OnSavePercent(v) {
      var Data = {
        BillSaleId: v.BillSaleId,
        RubberPercent: v.RubberPercent == "" ? 0 : v.RubberPercent,
      };
      Axios.put("/api/v1/OnSavePercent/billsale", Data).then((res) => {
        if (res.status == 200) {
          for (let index = 0; index < this.dsmainOpen.length; index++) {
            if (this.dsmainOpen[index].BillSaleId == v.BillSaleId) {
              this.dsmainOpen[index] = res.data;
              this.OnChoose(v);
            }
          }
          //this.selectRubber();
        }
      });
    },
    OnWait(v) {
      Axios.put("/api/v1/OnWait/billsale?BillSaleId=" + v.BillSaleId).then(
        (res) => {
          if (res.status == 200) {
            this.selectOpen();
            this.selectRubber();
          }
        }
      );
    },
    OnOpen(v) {
      Axios.put("/api/v1/OnOpen/billsale?BillSaleId=" + v.BillSaleId).then(
        (res) => {
          if (res.status == 200) {
            this.selectOpen();
            this.selectRubber();
          }
        }
      );
    },
    OnPayMent(v) {
      this.dialogdata.BillSaleId = v.BillSaleId;
      this.dialogdata.RubberId = v.RubberId;
      if (v.RubberId == null) return;
      Axios.get(
        "/api/v1/insert/OnPayMent/billsale?BillSaleId=" + v.BillSaleId
      ).then((res) => {
        if (res.status == 200) {
          this.dialog = true;
          this.dialogContent = res.data;
          this.dialogState = res.data[0].BillSales.BillSaleState;
          this.selectRubber();
        }
      });
    },
    OnDelete(v) {
      this.PassAccept("delete", () => {
        this.dialogContent.BillSaleId = v.BillSaleId;
        Axios.delete("/api/v1/Delete/billsale?BillSaleId=" + v.BillSaleId).then(
          (res) => {
            if (res.status == 200) {
              this.selectOpen();
              this.selectRubber();
              this.noti.Show("ลบแล้ว");
            }
          }
        );
      });
    },
  },
};
</script>
<style scoped>
.ww {
  width: 40%;

  display: inline-block;
}
input[readonly] {
  cursor: pointer;
}
</style>
